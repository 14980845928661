import React from 'react';

import { ReactComponent as CardMockFull } from '../assets/CardMockFull.svg';

const GradientBackground: React.FC = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
        position: 'relative',
      }}>
      <CardMockFull
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
        preserveAspectRatio="xMidYMid slice"
      />
    </div>
  );
};

export default GradientBackground;
