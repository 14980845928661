import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';

import { stringToColor } from '../utils/helpers';

interface UserProfileProps {
  name: string;
  email: string;
}

const UserProfile: React.FC<UserProfileProps> = ({ name, email }) => {
  const stringAvatar = (name: string): { sx: { bgcolor: string; mr: number }; children: string; alt: string } => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        mr: 2,
      },
      alt: `${name}`,
      children: `${name.charAt(0)}`,
    };
  };

  return (
    <Box display="flex" alignItems="center">
      <Avatar {...stringAvatar(name)} />

      <Box>
        <Typography variant="body1">{name}</Typography>
        <Typography variant="body2" color="textSecondary">
          {email}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserProfile;
