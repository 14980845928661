import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

import { UserSignInDto, UserSignUpDto } from '../../api/api';
import { AuthModalType } from '../../types/modalType';

interface AuthContentProps {
  actionType: AuthModalType;
  onSubmit: (formValues: UserSignInDto | UserSignUpDto) => void;
  onChangeType: (newType: AuthModalType) => void;
  getAccessCode: () => string;
  isLoading: boolean;
  error: string | undefined;
}

type AuthFormValues = Partial<UserSignInDto & UserSignUpDto>;

const AuthContent: React.FC<AuthContentProps> = ({
  actionType,
  onSubmit,
  onChangeType,
  getAccessCode,
  isLoading,
  error,
}) => {
  const [formValues, setFormValues] = useState<AuthFormValues>({
    email: '',
    password: '',
    accessCode: '',
  });

  const resetValues = () => {
    setFormValues({
      email: '',
      password: '',
      accessCode: '',
    });
    setConfirmPassword('');
  };

  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errors, setErrors] = useState({
    email: '',
    accessCode: '',
    password: '',
    confirmPassword: '',
  });

  const resetErrors = () => {
    setErrors({
      email: '',
      accessCode: '',
      password: '',
      confirmPassword: '',
    });
    setConfirmPassword('');
  };

  const handleChangeType = (newType: AuthModalType) => {
    resetErrors();
    resetValues();
    onChangeType(newType);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.target.value,
    }));
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const validate = (): boolean => {
    let isValid = true;
    const newErrors = { email: '', accessCode: '', password: '', confirmPassword: '' };

    if (actionType === AuthModalType.LOGIN) {
      newErrors.email = !formValues.email ? 'Email cannot be empty.' : '';
      newErrors.password = !formValues.password ? 'Password cannot be empty.' : '';
    }

    if (actionType === AuthModalType.SIGNUP || actionType === AuthModalType.RECOVERY) {
      newErrors.accessCode = !formValues.accessCode ? 'Code cannot be empty.' : '';
      newErrors.password =
        !formValues.password || formValues.password.length < 8 ? 'Password must be at least 8 characters.' : '';

      newErrors.confirmPassword =
        formValues.password !== confirmPassword || !confirmPassword ? 'Passwords do not match.' : '';
    }
    isValid = Object.values(newErrors).every((error) => error === '');

    setErrors(newErrors);

    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validate()) return;

    const params = {
      [AuthModalType.LOGIN]: {
        email: formValues.email || '',
        password: formValues.password || '',
      },
      [AuthModalType.RECOVERY]: {
        accessCode: formValues.accessCode || '',
        password: formValues.password || '',
      },
      [AuthModalType.SIGNUP]: {
        accessCode: formValues.accessCode || '',
        password: formValues.password || '',
      },
    };

    resetValues();

    const preparedData = params[actionType];

    onSubmit(preparedData);
  };

  const handleGetAccessCode = () => {
    const accessCodeUrl = getAccessCode();
    window.open(accessCodeUrl, '_blank');
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      {actionType === AuthModalType.LOGIN && (
        <>
          <TextField
            label="Email"
            name="email"
            onChange={handleChange}
            fullWidth
            placeholder="Enter your email"
            sx={{ mb: 2 }}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            onChange={handleChange}
            fullWidth
            placeholder="Enter your password"
            sx={{ mb: 2 }}
            error={Boolean(errors.password)}
            helperText={errors.password}
          />
          <Typography
            component="span"
            onClick={() => handleChangeType(AuthModalType.RECOVERY)}
            sx={{ cursor: 'pointer', color: 'blue', mb: 2 }}>
            Account Recovery
          </Typography>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" type="submit" sx={{ flexGrow: 1 }} disabled={isLoading}>
              Log in
            </Button>
          </Box>
          <Typography sx={{ mt: 2 }}>
            Don't have an account?{' '}
            <Typography
              component="span"
              onClick={() => handleChangeType(AuthModalType.SIGNUP)}
              sx={{ cursor: 'pointer', color: 'blue' }}>
              Signup
            </Typography>
          </Typography>
        </>
      )}
      {actionType === AuthModalType.SIGNUP && (
        <>
          <TextField
            label="Access code"
            name="accessCode"
            onChange={handleChange}
            fullWidth
            placeholder="Enter your access code"
            sx={{ mb: 2 }}
            error={Boolean(errors.accessCode)}
            helperText={errors.accessCode}
          />
          <Typography
            component="span"
            onClick={handleGetAccessCode}
            sx={{ cursor: 'pointer', color: 'blue', display: 'inline-block', mb: 2 }}>
            Get an Access Code
          </Typography>
          <TextField
            label="Password"
            name="password"
            type="password"
            onChange={handleChange}
            fullWidth
            placeholder="Create a password"
            helperText={errors.password || 'Must be at least 8 characters.'}
            sx={{ mb: 2 }}
            error={Boolean(errors.password)}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            fullWidth
            placeholder="Confirm password"
            sx={{ mb: 2 }}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
          />
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" type="submit" sx={{ flexGrow: 1 }} disabled={isLoading}>
              Signup
            </Button>
          </Box>
          <Typography
            component="span"
            onClick={() => handleChangeType(AuthModalType.LOGIN)}
            sx={{ cursor: 'pointer', color: 'blue', display: 'inline-block', mt: 2 }}>
            Return to login
          </Typography>
        </>
      )}
      {actionType === AuthModalType.RECOVERY && (
        <>
          <TextField
            label="Recovery code"
            name="accessCode"
            onChange={handleChange}
            fullWidth
            placeholder="Enter your recovery code"
            sx={{ mb: 2 }}
            error={Boolean(errors.accessCode)}
            helperText={errors.accessCode}
          />
          <TextField
            label="New Password"
            name="password"
            type="password"
            onChange={handleChange}
            fullWidth
            placeholder="Enter new password"
            helperText={errors.password || 'Must be at least 8 characters.'}
            sx={{ mb: 2 }}
            error={Boolean(errors.password)}
          />
          <TextField
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            onChange={handleConfirmPasswordChange}
            fullWidth
            placeholder="Confirm password"
            sx={{ mb: 2 }}
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
          />
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => handleChangeType(AuthModalType.LOGIN)}
              sx={{ flexGrow: 1 }}
              disabled={isLoading}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" sx={{ flexGrow: 1 }} disabled={isLoading}>
              Confirm
            </Button>
          </Box>
        </>
      )}
      <Box>
        <Typography sx={{ color: 'red', mt: 2 }}>{error}</Typography>
      </Box>
    </Box>
  );
};

export default AuthContent;
