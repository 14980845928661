import React from 'react';

import { AuthModalType } from '../../types/modalType';
import Modal from '../Modal';
import AuthContent from './AuthContent';
import modalContentMapping from './modalContentMapping';

interface AuthModalProps {
  type: AuthModalType;
  onClose: () => void;
  open: boolean;
  onSubmit: (formValues: any) => void;
  onChangeType: (newType: AuthModalType) => void;
  getAccessCode: () => string;
  isLoading: boolean;
  error: string | undefined;
}

const AuthModal: React.FC<AuthModalProps> = ({
  type,
  onClose,
  open,
  onSubmit,
  onChangeType,
  getAccessCode,
  isLoading,
  error,
}) => {
  const { label } = modalContentMapping[type];

  const content = (
    <AuthContent
      actionType={type}
      onSubmit={onSubmit}
      onChangeType={onChangeType}
      getAccessCode={getAccessCode}
      isLoading={isLoading}
      error={error}
    />
  );

  return (
    <Modal
      open={open}
      title={label}
      showCloseButton={true}
      modalContent={content}
      onClose={onClose}
      onBackdropClick={() => {}}
      disableBackdrop={true}
    />
  );
};

export default AuthModal;
