import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';

interface ModalProps {
  open: boolean;
  title: string;
  showCloseButton?: boolean;
  modalContent: React.ReactNode;
  actionButtons?: React.ReactNode;
  onBackdropClick?: () => void;
  onClose?: () => void;
  sx?: SxProps<Theme>;
  disableBackdrop?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  open,
  title,
  showCloseButton = true,
  modalContent,
  actionButtons,
  onBackdropClick,
  onClose,
  sx = {},
  disableBackdrop = false,
}) => {
  const handleClose = (event: React.MouseEvent | React.KeyboardEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' && onBackdropClick) {
      onBackdropClick();
      return;
    } else if (onClose) {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ ...sx }}
      BackdropProps={disableBackdrop ? { style: { backgroundColor: 'transparent' } } : {}}>
      <DialogTitle
        variant="h6"
        sx={{
          m: 0,
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        {title}
        {showCloseButton && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent dividers>{modalContent}</DialogContent>
      {actionButtons && <DialogActions>{actionButtons}</DialogActions>}
    </Dialog>
  );
};

export default Modal;
