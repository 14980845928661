import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';

import { CustomFieldLabelDto, CustomFieldValuesDto, TokenInfoDto, TokenListItemDto } from '../../api/api';
import { useNetworksList } from '../../hooks/useNetworksList';
import { PartnerModalType } from '../../types/modalType';
import { convertCustomFieldValuesToObject, formatLabelToLowerCase } from '../../utils/helpers';

interface PartnerContentProps {
  listId: number;
  actionType: PartnerModalType;
  onSubmit: (formValues: any) => void;
  onCancel: () => void;
  partnerData?: TokenListItemDto;
  customFieldsLabels?: CustomFieldLabelDto[];
}

const defaultToken: TokenInfoDto = {
  name: '',
  address: '',
  type: TokenInfoDto.TypeEnum.ERC20,
  symbol: '',
  networkId: 1,
};

const PartnerContent: React.FC<PartnerContentProps> = ({
  listId,
  actionType,
  customFieldsLabels,
  partnerData,
  onSubmit,
  onCancel,
}) => {
  const { networksList } = useNetworksList();

  const [formValues, setFormValues] = useState<TokenListItemDto>({
    id: partnerData?.id ?? 0,
    note: partnerData?.note || '',
    token: partnerData?.token || defaultToken,
    customFieldsValues: convertCustomFieldValuesToObject(partnerData?.customFieldsValues),
    isActive: partnerData?.isActive ?? true,
    overallUsage: partnerData?.overallUsage ?? 0,
    uniqueUsers: partnerData?.uniqueUsers ?? 0,
  });

  const handleChange =
    (field: keyof TokenListItemDto | keyof TokenInfoDto | keyof CustomFieldValuesDto) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value?: any) => {
      const inputValue = event?.target?.value ?? value;

      setFormValues((prevValues) => {
        const updatedCustomFields = {
          ...prevValues.customFieldsValues,
          [event.target.name]: inputValue,
        };

        return {
          ...prevValues,
          token: field in defaultToken ? { ...prevValues.token, [field]: inputValue } : prevValues.token,
          customFieldsValues: updatedCustomFields,
          ...(field !== 'customFieldsValues' && !(field in defaultToken) ? { [field]: inputValue } : {}),
        };
      });
    };

  const handleCustomFieldChange = (name: string) => (event: any, value?: any) => {
    const inputValue = event?.target?.value ?? value;

    setFormValues((prevValues) => ({
      ...prevValues,
      customFieldsValues: {
        ...prevValues.customFieldsValues,
        [name]: inputValue,
      },
    }));
  };

  const handleSelectChange = (field: keyof TokenInfoDto) => (event: SelectChangeEvent<number>) => {
    const value = event.target.value;

    setFormValues((prevValues) => ({
      ...prevValues,
      token: { ...prevValues.token, [field]: value },
    }));
  };

  const prepareCustomFieldsArray = () => {
    return Object.entries(formValues.customFieldsValues)
      .map(([name, value]) => {
        const label = customFieldsLabels?.find((label) => formatLabelToLowerCase(label.name) === name.toLowerCase());

        const params = {
          [PartnerModalType.ADD_PARTNER]: { customFieldId: label?.customFieldId, value },
          [PartnerModalType.EDIT_PARTNER]: { customFieldId: label?.customFieldId, newValue: value },
        };

        if (label) return params[actionType];
      })
      .filter(Boolean);
  };

  const handleSubmit = async () => {
    const customFieldsArray = prepareCustomFieldsArray();

    const params = {
      [PartnerModalType.ADD_PARTNER]: {
        listId,
        networkId: Number(formValues.token.networkId),
        address: formValues.token.address,
        note: formValues.note,
        customFieldsValues: customFieldsArray,
      },
      [PartnerModalType.EDIT_PARTNER]: {
        itemId: formValues.id,
        newNote: formValues.note,
        isActive: formValues.isActive,
        isArchived: false,
        customFieldsValues: customFieldsArray,
      },
    };

    const preparedData = params[actionType];

    onSubmit(preparedData);
  };

  const fieldConfigurations: { name: keyof TokenInfoDto; label: string }[] = [
    { name: 'address', label: 'Address' },
    { name: 'networkId', label: 'Network' },
    { name: 'symbol', label: 'Symbol' },
    { name: 'name', label: 'Name' },
  ];

  const fieldRenderMapping: Record<string, (name: keyof TokenInfoDto, disabled: boolean) => React.ReactNode> = {
    networkId: (name, disabled) => (
      <FormControl fullWidth variant="outlined">
        <Select
          id={name}
          value={formValues.token.networkId}
          onChange={handleSelectChange(name)}
          disabled={disabled}
          sx={{
            backgroundColor: disabled ? '#f5f5f5' : 'inherit',
            color: disabled ? '#888' : 'inherit',
          }}>
          {networksList?.map((network) => (
            <MenuItem key={network.id} value={network.id}>
              {network.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    ),
    default: (name, disabled) => (
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          color="primary"
          id={name}
          value={formValues.token[name] || ''}
          onChange={handleChange(name)}
          disabled={disabled}
          sx={{
            backgroundColor: disabled ? '#f5f5f5' : 'inherit',
            color: disabled ? '#888' : 'inherit',
          }}
        />
      </FormControl>
    ),
  };

  const renderFormField = (fieldConfig: { name: keyof TokenInfoDto; label: string }) => {
    const { name, label } = fieldConfig;
    const disabled = isFieldDisabled(name);

    return (
      <Grid container key={name} sx={{ mb: 2 }}>
        <Grid item xs={2}>
          {label}
        </Grid>
        <Grid item xs={10}>
          {fieldRenderMapping[name]
            ? fieldRenderMapping[name](name, disabled)
            : fieldRenderMapping.default(name, disabled)}
        </Grid>
      </Grid>
    );
  };

  const isFieldDisabled = (field: string) => {
    const disabledFields: Record<PartnerModalType, string[]> = {
      [PartnerModalType.EDIT_PARTNER]: ['name', 'symbol', 'address', 'networkId'],
      [PartnerModalType.ADD_PARTNER]: ['name', 'symbol'],
    };

    return disabledFields[actionType]?.includes(field) || false;
  };

  const fieldTypeMap: Record<
    CustomFieldLabelDto.CustomFieldTypeEnum,
    (field: CustomFieldLabelDto, fieldValue: any, handleChange: any) => JSX.Element
  > = {
    [CustomFieldLabelDto.CustomFieldTypeEnum.STRING]: (field, fieldValue, handleChange) => (
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        name={field.name}
        value={fieldValue || ''}
        onChange={handleChange}
      />
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.NUMBER]: (field, fieldValue, handleChange) => (
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        type="number"
        name={field.name}
        value={fieldValue || ''}
        onChange={handleChange}
      />
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.BOOLEAN]: (field, fieldValue, handleChange) => (
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        select
        name={field.name}
        value={fieldValue || 'false'}
        onChange={handleChange}>
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </TextField>
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.DATE]: (field, fieldValue, handleChange) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={fieldValue ? dayjs(fieldValue) : null}
          onChange={(date: Dayjs | null) =>
            handleChange({ target: { name: field.name, value: date ? date.format('YYYY-MM-DD') : '' } })
          }
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'small',
              fullWidth: true,
            },
          }}
        />
      </LocalizationProvider>
    ),
  };

  const renderCustomField = (field: CustomFieldLabelDto) => {
    const customFieldValueName = formatLabelToLowerCase(field.name);
    const fieldValue =
      formValues.customFieldsValues[customFieldValueName as keyof CustomFieldValuesDto] ||
      customFieldsLabels?.find((label) => label.name === field.name)?.defaultValue ||
      '';
    const handleChangeForField = handleCustomFieldChange(customFieldValueName);

    const inputComponent = fieldTypeMap[field.customFieldType]
      ? fieldTypeMap[field.customFieldType](field, fieldValue, handleChangeForField)
      : fieldTypeMap[CustomFieldLabelDto.CustomFieldTypeEnum.STRING](field, fieldValue, handleChangeForField);

    return (
      <Grid container key={field.customFieldId} sx={{ mb: 2, borderBottom: '1px solid #ddd' }}>
        <Grid item xs={4} sx={{ padding: '8px 16px' }}>
          {field.name}
        </Grid>
        <Grid item xs={4} sx={{ padding: '8px 16px', textTransform: 'capitalize' }}>
          {field.customFieldType.toString().toLowerCase()}
        </Grid>
        <Grid item xs={4} sx={{ padding: '8px 16px' }}>
          {inputComponent}
        </Grid>
      </Grid>
    );
  };

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ mt: 2, p: '1rem' }}>
      <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        {fieldConfigurations.map(renderFormField)}
        <Divider sx={{ width: '100%', margin: '1rem 0' }} />
        <Typography variant="h6" sx={{ mb: 2 }}>
          Custom Fields
        </Typography>
        <Grid container sx={{ mb: 2, borderBottom: '2px solid #ddd' }}>
          {['Label', 'Type', 'Value'].map((header) => (
            <Grid key={header} item xs={4} sx={{ padding: '8px 16px', fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
              {header}
            </Grid>
          ))}
        </Grid>
        {customFieldsLabels?.map(renderCustomField)}
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={2}>
            <Typography>Notes</Typography>
          </Grid>
          <Grid item xs={10}>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="note"
                value={formValues.note || ''}
                onChange={handleChange('note')}
                placeholder="Write a few notes about this token..."
                multiline
                rows={4}
                sx={{
                  backgroundColor: isFieldDisabled('note') ? '#f5f5f5' : 'inherit',
                  color: isFieldDisabled('note') ? '#888' : 'inherit',
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined" onClick={onCancel} sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {actionType === PartnerModalType.ADD_PARTNER ? 'Add Partner' : 'Save Changes'}
        </Button>
      </Box>
    </Box>
  );
};

export default PartnerContent;
