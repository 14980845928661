import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useProjectInfo } from '../hooks/useProjectInfo';
import SimpleCard from './SimpleCard';

interface ApiKeyBlockProps {
  projectId: string;
}

interface FieldProps {
  label: string;
  value?: string;
}

const Field: React.FC<FieldProps> = ({ label, value = '' }) => (
  <Box display="flex" alignItems="center" mb={1} sx={{ flexWrap: 'nowrap' }}>
    <Typography variant="body2" sx={{ width: '5rem', marginRight: '0.5rem', color: 'grey.600' }}>
      {label}
    </Typography>
    <Box
      flex="1"
      bgcolor="#f9f9f9"
      padding="0.5rem 1rem"
      borderRadius="4px"
      border="1px solid #ddd"
      display="flex"
      alignItems="center"
      sx={{
        maxWidth: 'calc(100% - 7rem)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>
      <Typography variant="body2" noWrap>
        {value || ' '}
      </Typography>
    </Box>
    <CopyToClipboard text={value}>
      <IconButton edge="end" sx={{ ml: 0.5, padding: '0.25rem' }} aria-label={`Copy ${label}`}>
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </CopyToClipboard>
  </Box>
);

const ApiKeyBlock: React.FC<ApiKeyBlockProps> = ({ projectId }) => {
  const { data, generateApiKey, isLoading, isGenerating } = useProjectInfo(projectId);

  const handleGenerateNew = async () => {
    await generateApiKey();
  };

  const fields = [
    { label: 'API key', value: data?.apiKey },
    { label: 'url', value: data?.url },
  ];

  return (
    <SimpleCard>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
        <Typography variant="h6">API Key</Typography>
        <LoadingButton
          variant="outlined"
          onClick={handleGenerateNew}
          loading={isLoading || isGenerating}
          size="small"
          sx={{
            padding: '0.25rem 0.75rem',
            fontWeight: 'bold',
            fontSize: '0.75rem',
            borderRadius: '4px',
          }}>
          Generate New Key
        </LoadingButton>
      </Box>

      {fields.map((field, index) => (
        <Field key={index} label={field.label} value={field.value} />
      ))}
    </SimpleCard>
  );
};

export default ApiKeyBlock;
