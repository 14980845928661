import useSWR from 'swr';

import { NetworkInfoDto } from '../api/api';
import { Endpoints } from '../constants/endpoints';
import { getNetworksList } from '../utils/api';

export const useNetworksList = () => {
  const fetchNetworksList = async (): Promise<NetworkInfoDto[] | undefined> => {
    const response = await getNetworksList();
    if (response) return response.data;
  };

  const { data, error } = useSWR<NetworkInfoDto[] | undefined>(Endpoints.GetNetworksList, fetchNetworksList);

  return {
    networksList: data,
    isLoading: !error && !data,
    error,
  };
};
