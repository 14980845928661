import { ProjectApi, UserApi, TokenApi, ListApi } from './api';
import { Configuration } from './generated/configuration';
import { BASE_API_URL } from '../constants/config';

const ApiClient = () => {
  const config = new Configuration({
    basePath: BASE_API_URL,
  });

  return {
    ProjectApi: new ProjectApi(config),
    ListApi: new ListApi(config),
    TokenApi: new TokenApi(config),
    UserApi: new UserApi(config),
  };
};

export default ApiClient;
