import useSWR from 'swr';

import { CreateCustomFieldsDto, TokenListDto, UpdateTokenListCustomFieldsDto, UpdateTokenListDto } from '../api/api';
import { createCustomFields, updateTokenList, updateTokenListCustomFields } from '../utils/api';
import { usePartnersList } from './usePartnersList';
import { useUserInfo } from './useUserInfo';

export const useTokenListManagement = () => {
  const { userInfo, isLoading: isUserInfoLoading, error: userInfoError } = useUserInfo();
  const listId = userInfo?.projects[0]?.tokenLists[0]?.id;

  const { fetchPartnersList } = usePartnersList();

  const { data, error, mutate } = useSWR<TokenListDto | undefined>(
    listId && !isUserInfoLoading && !userInfoError ? `tokenList-${listId}` : null,
    fetchPartnersList
  );

  const updateList = async (updateData: UpdateTokenListDto) => {
    if (listId) {
      await updateTokenList({ ...updateData, listId });
      mutate();
    }
  };

  const updateCustomFields = async (updateData: UpdateTokenListCustomFieldsDto) => {
    if (listId) {
      await updateTokenListCustomFields({ ...updateData, listId });
      mutate();
    }
  };

  const createNewCustomFields = async (createData: CreateCustomFieldsDto) => {
    if (listId) {
      await createCustomFields({ ...createData, listId });
      mutate();
    }
  };

  return {
    tokenList: data,
    isLoading: isUserInfoLoading || (!error && !data),
    error: userInfoError || error,
    updateList,
    updateCustomFields,
    createNewCustomFields,
  };
};
