import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { BASE_PATH } from '../constants/routes';
import { useUserInfo } from '../hooks/useUserInfo';
import Layout from './Layout';

const LayoutRoutes = () => {
  const { isAuthenticated } = useUserInfo();

  if (!isAuthenticated()) {
    return <Navigate to={BASE_PATH.WELCOME} />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default LayoutRoutes;
