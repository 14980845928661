import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import React, { ReactNode } from 'react';

const StyledCard = styled(Card)({
  border: '1px solid grey',
  padding: '1rem',
});

interface SimpleCardProps {
  children: ReactNode;
}

const SimpleCard: React.FC<SimpleCardProps> = ({ children }) => {
  return <StyledCard>{children}</StyledCard>;
};

export default SimpleCard;
