import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

import { CustomFieldValuesDto, TokenListItemDto } from '../api/api';
import { PartnerListProps } from '../types/partnerTypes';
import { convertCustomFieldValuesToObject, formatLabelToLowerCase, toCamelCase } from '../utils/helpers';

interface Column {
  id: string;
  label: string;
  format?: (value: any) => string;
  accessor?: (item: TokenListItemDto) => any;
}

const PartnerList: React.FC<PartnerListProps> = ({ list, handleRowClick }) => {
  const columns: Column[] = [
    { id: 'name', label: 'Partner', accessor: (item: TokenListItemDto) => item.token.name },
    { id: 'overallUsage', label: 'Overall Usage', accessor: (item: TokenListItemDto) => item.overallUsage },
    { id: 'uniqueUsers', label: 'Unique Users', accessor: (item: TokenListItemDto) => item.uniqueUsers },
    ...list.customFieldsLabels.map((label) => {
      const key = toCamelCase(label.name);
      return {
        id: key,
        label: label.name,
        accessor: (item: TokenListItemDto) => {
          const customField = convertCustomFieldValuesToObject(item.customFieldsValues);
          return customField[formatLabelToLowerCase(label.name) as keyof CustomFieldValuesDto] || label.defaultValue;
        },
      };
    }),
    { id: 'note', label: 'Note', accessor: (item: TokenListItemDto) => item.note },
  ];

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id}>{column.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.items.map((item) => (
            <TableRow hover key={item.id} onClick={() => handleRowClick(item)}>
              {columns.map((column) => {
                const value = column.accessor ? column.accessor(item) : (item as any)[column.id];
                return <TableCell key={column.id}>{column.format ? column.format(value) : value}</TableCell>;
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PartnerList;
