import { createTheme } from '@mui/material/styles';

const baseTheme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontSize: '6rem', // 96px
    },
    h2: {
      fontSize: '3.75rem', // 60px
      fontWeight: 400,
    },
    h3: {
      fontSize: '3rem', // 48px
    },
    h4: {
      fontSize: '2.125rem', // 34px
    },
    h5: {
      fontSize: '1.5rem', // 24px
    },
    h6: {
      fontSize: '1.25rem', // 20px
    },
    subtitle1: {
      fontSize: '1rem', // 16px
    },
    subtitle2: {
      fontSize: '0.875rem', // 14px
    },
    body1: {
      fontSize: '1rem', // 16px
    },
    body2: {
      fontSize: '0.875rem', // 14px
    },
    caption: {
      fontSize: '0.75rem', // 12px
    },
    overline: {
      fontSize: '0.75rem', // 12px
    },
  },
});

export default baseTheme;
