import { useState } from 'react';
import useSWR from 'swr';

import { ApiKeyDataDto, GenerateApiKeyDto } from '../api/api';
import { generateNewApiKey, getApiKeyByProjectId } from '../utils/api';
import { useUserInfo } from './useUserInfo';

interface UseProjectInfoReturn {
  isLoading: boolean;
  isGenerating: boolean;
  error: Error | null;
  generateApiKey: () => Promise<void>;
  data: ApiKeyDataDto | undefined;
}

export const useProjectInfo = (projectId: string): UseProjectInfoReturn => {
  const { isLoading: isUserInfoLoading, error: userInfoError } = useUserInfo();
  const [isGenerating, setIsGenerating] = useState(false);

  const { data, error, mutate } = useSWR<ApiKeyDataDto | undefined>(
    projectId ? `/projects/${projectId}/apikey` : null,
    async () => {
      const result = await getApiKeyByProjectId(projectId);
      if (result) return result.data;
    }
  );

  const generateApiKey = async () => {
    setIsGenerating(true);
    try {
      const requestPayload: GenerateApiKeyDto = { projectId: Number(projectId) };
      await generateNewApiKey(requestPayload);
      const result = await getApiKeyByProjectId(projectId);
      if (result && result.data) mutate(result.data, false);
    } catch (err) {
      console.error('Error generating new API key:', err);
    } finally {
      setIsGenerating(false);
    }
  };

  return {
    isLoading: isUserInfoLoading || (!error && !data),
    isGenerating,
    error: userInfoError || error,
    generateApiKey,
    data,
  };
};
