import useSWR from 'swr';

import { CreateTokenListItemDto, TokenListDto, UpdateTokenListItemDto } from '../api/api';
import { createToken, getPartnersList, updateToken as updateTokenApi } from '../utils/api';
import { useUserInfo } from './useUserInfo';

export const usePartnersList = () => {
  const { userInfo, isLoading: isUserInfoLoading, error: userInfoError } = useUserInfo();

  const listId = userInfo?.projects[0]?.tokenLists[0]?.id;

  const fetchPartnersList = async (): Promise<TokenListDto | undefined> => {
    if (listId) {
      const response = await getPartnersList(listId.toString());
      if (response) return response.data;
    }
  };

  const { data, error, mutate } = useSWR<TokenListDto | undefined>(
    listId && !isUserInfoLoading && !userInfoError ? `partnersList-${listId}` : null,
    fetchPartnersList
  );

  const addToken = async (tokenData: CreateTokenListItemDto) => {
    if (listId) {
      await createToken(tokenData);
      mutate();
    }
  };

  const updateToken = async (tokenData: UpdateTokenListItemDto) => {
    if (tokenData.itemId) {
      await updateTokenApi(tokenData);
      mutate();
    }
  };

  return {
    partnerList: data,
    isLoading: isUserInfoLoading || (!error && !data),
    error: userInfoError || error,
    addToken,
    updateToken,
    fetchPartnersList,
  };
};
