import React from 'react';

import { PartnerModalType } from '../../types/modalType';

type ModalContent = {
  label: string;
  actionButtons?: React.ReactNode;
};

const modalContentMapping: Record<PartnerModalType, ModalContent> = {
  [PartnerModalType.ADD_PARTNER]: {
    label: 'Add Partner',
  },
  [PartnerModalType.EDIT_PARTNER]: {
    label: 'Edit Partner',
  },
};

export default modalContentMapping;
