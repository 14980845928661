import React from 'react';

import { CreateTokenListItemDto, CustomFieldLabelDto, TokenListItemDto, UpdateTokenListItemDto } from '../../api/api';
import { PartnerModalType } from '../../types/modalType';
import Modal from '../Modal';
import modalContentMapping from './modalContentMapping';
import PartnerContent from './PartnerContent';

interface PartnerModalProps {
  type: PartnerModalType;
  onClose: () => void;
  listId: number;
  open: boolean;
  partnerData?: TokenListItemDto;
  customFieldsLabels?: CustomFieldLabelDto[];
  onSubmit: (formValues: CreateTokenListItemDto | UpdateTokenListItemDto) => void;
}

const PartnerModal: React.FC<PartnerModalProps> = ({
  type,
  onClose,
  listId,
  open,
  partnerData,
  customFieldsLabels,
  onSubmit,
}) => {
  const { label } = modalContentMapping[type];

  const content = (
    <PartnerContent
      actionType={type}
      onSubmit={onSubmit}
      onCancel={onClose}
      listId={listId}
      partnerData={type === PartnerModalType.EDIT_PARTNER ? partnerData : undefined}
      customFieldsLabels={customFieldsLabels}
    />
  );

  return <Modal open={open} title={label} showCloseButton={true} modalContent={content} onClose={onClose} />;
};

export default PartnerModal;
