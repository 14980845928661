// @ts-nocheck
/// <reference path="./generated/custom.d.ts" />
// tslint:disable
/**
 * TOKEN TIES API
 * Token Ties is a platform that allows users to create and manage lists of tokens for any user.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from 'url';
import * as isomorphicFetch from 'isomorphic-fetch';
import { Configuration } from './generated/configuration';

const BASE_PATH = '/'.replace(/\/+$/, '');

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ',',
  ssv: ' ',
  tsv: '\t',
  pipes: '|',
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
  (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected fetch: FetchAPI = isomorphicFetch
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name = 'RequiredError';
  constructor(
    public field: string,
    msg?: string
  ) {
    super(msg);
  }
}

/**
 *
 * @export
 * @interface AccessCodeDataDto
 */
export interface AccessCodeDataDto {
  /**
   * The access code
   * @type {string}
   * @memberof AccessCodeDataDto
   */
  accessCode: string;
}
/**
 *
 * @export
 * @interface AccessCodeResponseDto
 */
export interface AccessCodeResponseDto {
  /**
   * The status
   * @type {string}
   * @memberof AccessCodeResponseDto
   */
  status: AccessCodeResponseDto.StatusEnum;
  /**
   * The status message
   * @type {string}
   * @memberof AccessCodeResponseDto
   */
  message?: string;
  /**
   * The access code data
   * @type {AccessCodeDataDto}
   * @memberof AccessCodeResponseDto
   */
  data?: AccessCodeDataDto;
}

/**
 * @export
 * @namespace AccessCodeResponseDto
 */
export namespace AccessCodeResponseDto {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    OK = <any>'OK',
    ERROR = <any>'ERROR',
  }
}
/**
 *
 * @export
 * @interface ApiKeyDataDto
 */
export interface ApiKeyDataDto {
  /**
   * The api key
   * @type {string}
   * @memberof ApiKeyDataDto
   */
  apiKey: string;
  /**
   * The url
   * @type {string}
   * @memberof ApiKeyDataDto
   */
  url: string;
}
/**
 *
 * @export
 * @interface ApiKeyResponseDto
 */
export interface ApiKeyResponseDto {
  /**
   * The status
   * @type {string}
   * @memberof ApiKeyResponseDto
   */
  status: ApiKeyResponseDto.StatusEnum;
  /**
   * The status message
   * @type {string}
   * @memberof ApiKeyResponseDto
   */
  message?: string;
  /**
   * The api key
   * @type {ApiKeyDataDto}
   * @memberof ApiKeyResponseDto
   */
  data?: ApiKeyDataDto;
}

/**
 * @export
 * @namespace ApiKeyResponseDto
 */
export namespace ApiKeyResponseDto {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    OK = <any>'OK',
    ERROR = <any>'ERROR',
  }
}
/**
 *
 * @export
 * @interface CheckAddressDataDto
 */
export interface CheckAddressDataDto {
  /**
   * The token list item token
   * @type {TokenInfoDto}
   * @memberof CheckAddressDataDto
   */
  token: TokenInfoDto;
  /**
   * The token list item custom fields values
   * @type {CustomFieldValuesDto}
   * @memberof CheckAddressDataDto
   */
  customFieldValues: CustomFieldValuesDto;
  /**
   * The token balance
   * @type {string}
   * @memberof CheckAddressDataDto
   */
  balance: string;
}
/**
 *
 * @export
 * @interface CheckAddressDto
 */
export interface CheckAddressDto {
  /**
   * The user address
   * @type {string}
   * @memberof CheckAddressDto
   */
  address: string;
  /**
   * The project API key
   * @type {string}
   * @memberof CheckAddressDto
   */
  apiKey: string;
  /**
   * The token list id
   * @type {number}
   * @memberof CheckAddressDto
   */
  listId: number;
}
/**
 *
 * @export
 * @interface CheckAddressResponseDto
 */
export interface CheckAddressResponseDto {
  /**
   * The status
   * @type {string}
   * @memberof CheckAddressResponseDto
   */
  status: CheckAddressResponseDto.StatusEnum;
  /**
   * The status message
   * @type {string}
   * @memberof CheckAddressResponseDto
   */
  message?: string;
  /**
   * The address check data
   * @type {Array<CheckAddressDataDto>}
   * @memberof CheckAddressResponseDto
   */
  data?: Array<CheckAddressDataDto>;
}

/**
 * @export
 * @namespace CheckAddressResponseDto
 */
export namespace CheckAddressResponseDto {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    OK = <any>'OK',
    ERROR = <any>'ERROR',
  }
}
/**
 *
 * @export
 * @interface CreateCustomFieldLabelDto
 */
export interface CreateCustomFieldLabelDto {
  /**
   * The custom field label
   * @type {string}
   * @memberof CreateCustomFieldLabelDto
   */
  name: string;
  /**
   * The custom field type
   * @type {string}
   * @memberof CreateCustomFieldLabelDto
   */
  customFieldType: CreateCustomFieldLabelDto.CustomFieldTypeEnum;
  /**
   * The custom field default value
   * @type {string}
   * @memberof CreateCustomFieldLabelDto
   */
  defaultValue: string;
}

/**
 * @export
 * @namespace CreateCustomFieldLabelDto
 */
export namespace CreateCustomFieldLabelDto {
  /**
   * @export
   * @enum {string}
   */
  export enum CustomFieldTypeEnum {
    STRING = <any>'STRING',
    NUMBER = <any>'NUMBER',
    BOOLEAN = <any>'BOOLEAN',
    DATE = <any>'DATE',
  }
}
/**
 *
 * @export
 * @interface CreateCustomFieldValueDto
 */
export interface CreateCustomFieldValueDto {
  /**
   * The custom field label id
   * @type {number}
   * @memberof CreateCustomFieldValueDto
   */
  customFieldId: number;
  /**
   * The custom field value
   * @type {string}
   * @memberof CreateCustomFieldValueDto
   */
  value: string;
}
/**
 *
 * @export
 * @interface CreateCustomFieldsDto
 */
export interface CreateCustomFieldsDto {
  /**
   * The token list id
   * @type {number}
   * @memberof CreateCustomFieldsDto
   */
  listId: number;
  /**
   * The token list customFields
   * @type {Array<CreateCustomFieldLabelDto>}
   * @memberof CreateCustomFieldsDto
   */
  customFields: Array<CreateCustomFieldLabelDto>;
}
/**
 *
 * @export
 * @interface CreateTokenListItemDto
 */
export interface CreateTokenListItemDto {
  /**
   * The token list id
   * @type {number}
   * @memberof CreateTokenListItemDto
   */
  listId: number;
  /**
   * The network id
   * @type {number}
   * @memberof CreateTokenListItemDto
   */
  networkId: number;
  /**
   * The token address
   * @type {string}
   * @memberof CreateTokenListItemDto
   */
  address: string;
  /**
   * The token list item note
   * @type {string}
   * @memberof CreateTokenListItemDto
   */
  note?: string;
  /**
   * The token custom fields
   * @type {Array<CreateCustomFieldValueDto>}
   * @memberof CreateTokenListItemDto
   */
  customFieldsValues: Array<CreateCustomFieldValueDto>;
}
/**
 *
 * @export
 * @interface CustomFieldLabelDto
 */
export interface CustomFieldLabelDto {
  /**
   * The custom field id
   * @type {number}
   * @memberof CustomFieldLabelDto
   */
  customFieldId: number;
  /**
   * The custom field label
   * @type {string}
   * @memberof CustomFieldLabelDto
   */
  name: string;
  /**
   * The custom field type
   * @type {string}
   * @memberof CustomFieldLabelDto
   */
  customFieldType: CustomFieldLabelDto.CustomFieldTypeEnum;
  /**
   * The custom field default value
   * @type {string}
   * @memberof CustomFieldLabelDto
   */
  defaultValue: string;
}

/**
 * @export
 * @namespace CustomFieldLabelDto
 */
export namespace CustomFieldLabelDto {
  /**
   * @export
   * @enum {string}
   */
  export enum CustomFieldTypeEnum {
    STRING = <any>'STRING',
    NUMBER = <any>'NUMBER',
    BOOLEAN = <any>'BOOLEAN',
    DATE = <any>'DATE',
  }
}
/**
 *
 * @export
 * @interface CustomFieldValuesDto
 */
export interface CustomFieldValuesDto {
  /**
   * The custom field value
   * @type {any}
   * @memberof CustomFieldValuesDto
   */
  lable: any;
}
/**
 *
 * @export
 * @interface GenerateAccessCodeDto
 */
export interface GenerateAccessCodeDto {
  /**
   * The user email
   * @type {string}
   * @memberof GenerateAccessCodeDto
   */
  email: string;
  /**
   * The user name
   * @type {string}
   * @memberof GenerateAccessCodeDto
   */
  name?: string;
  /**
   * The access code type
   * @type {string}
   * @memberof GenerateAccessCodeDto
   */
  type: GenerateAccessCodeDto.TypeEnum;
  /**
   * The admin secret token
   * @type {string}
   * @memberof GenerateAccessCodeDto
   */
  secretToken: string;
}

/**
 * @export
 * @namespace GenerateAccessCodeDto
 */
export namespace GenerateAccessCodeDto {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    RECOVERY = <any>'RECOVERY',
    REGISTRATION = <any>'REGISTRATION',
  }
}
/**
 *
 * @export
 * @interface GenerateApiKeyDto
 */
export interface GenerateApiKeyDto {
  /**
   * The project id
   * @type {number}
   * @memberof GenerateApiKeyDto
   */
  projectId: number;
}
/**
 *
 * @export
 * @interface JwtDataDto
 */
export interface JwtDataDto {
  /**
   *
   * @type {string}
   * @memberof JwtDataDto
   */
  jwt: string;
}
/**
 *
 * @export
 * @interface NetworkInfoDto
 */
export interface NetworkInfoDto {
  /**
   * The network id
   * @type {number}
   * @memberof NetworkInfoDto
   */
  id: number;
  /**
   * The network name
   * @type {string}
   * @memberof NetworkInfoDto
   */
  name: string;
  /**
   * The network chain Id
   * @type {number}
   * @memberof NetworkInfoDto
   */
  chainId?: number;
  /**
   * The network engine
   * @type {string}
   * @memberof NetworkInfoDto
   */
  engine: string;
}
/**
 *
 * @export
 * @interface NetworksInfoResponseDto
 */
export interface NetworksInfoResponseDto {
  /**
   * The status
   * @type {string}
   * @memberof NetworksInfoResponseDto
   */
  status: NetworksInfoResponseDto.StatusEnum;
  /**
   * The status message
   * @type {string}
   * @memberof NetworksInfoResponseDto
   */
  message?: string;
  /**
   * The network info
   * @type {Array<NetworkInfoDto>}
   * @memberof NetworksInfoResponseDto
   */
  data?: Array<NetworkInfoDto>;
}

/**
 * @export
 * @namespace NetworksInfoResponseDto
 */
export namespace NetworksInfoResponseDto {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    OK = <any>'OK',
    ERROR = <any>'ERROR',
  }
}
/**
 *
 * @export
 * @interface ProjectInfoDto
 */
export interface ProjectInfoDto {
  /**
   * The project id
   * @type {number}
   * @memberof ProjectInfoDto
   */
  id: number;
  /**
   * The project name
   * @type {string}
   * @memberof ProjectInfoDto
   */
  name: string;
  /**
   * The project note
   * @type {string}
   * @memberof ProjectInfoDto
   */
  note?: string;
  /**
   * The token lists
   * @type {Array<TokenListCompactDto>}
   * @memberof ProjectInfoDto
   */
  tokenLists: Array<TokenListCompactDto>;
}
/**
 *
 * @export
 * @interface ResponseStatusDto
 */
export interface ResponseStatusDto {
  /**
   * The status
   * @type {string}
   * @memberof ResponseStatusDto
   */
  status: ResponseStatusDto.StatusEnum;
  /**
   * The status message
   * @type {string}
   * @memberof ResponseStatusDto
   */
  message?: string;
  /**
   * The data
   * @type {any}
   * @memberof ResponseStatusDto
   */
  data?: any;
}

/**
 * @export
 * @namespace ResponseStatusDto
 */
export namespace ResponseStatusDto {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    OK = <any>'OK',
    ERROR = <any>'ERROR',
  }
}
/**
 *
 * @export
 * @interface SignInResponseDto
 */
export interface SignInResponseDto {
  /**
   * The status
   * @type {string}
   * @memberof SignInResponseDto
   */
  status: SignInResponseDto.StatusEnum;
  /**
   * The status message
   * @type {string}
   * @memberof SignInResponseDto
   */
  message?: string;
  /**
   * The JWT token
   * @type {JwtDataDto}
   * @memberof SignInResponseDto
   */
  data: JwtDataDto;
}

/**
 * @export
 * @namespace SignInResponseDto
 */
export namespace SignInResponseDto {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    OK = <any>'OK',
    ERROR = <any>'ERROR',
  }
}
/**
 *
 * @export
 * @interface TokenInfoDto
 */
export interface TokenInfoDto {
  /**
   * The token name
   * @type {string}
   * @memberof TokenInfoDto
   */
  name: string;
  /**
   * The token address
   * @type {string}
   * @memberof TokenInfoDto
   */
  address: string;
  /**
   * The token type
   * @type {string}
   * @memberof TokenInfoDto
   */
  type: TokenInfoDto.TypeEnum;
  /**
   * The token symbol
   * @type {string}
   * @memberof TokenInfoDto
   */
  symbol: string;
  /**
   * The token network id
   * @type {number}
   * @memberof TokenInfoDto
   */
  networkId: number;
}

/**
 * @export
 * @namespace TokenInfoDto
 */
export namespace TokenInfoDto {
  /**
   * @export
   * @enum {string}
   */
  export enum TypeEnum {
    ERC20 = <any>'ERC20',
    ERC721 = <any>'ERC721',
    ERC1155 = <any>'ERC1155',
  }
}
/**
 *
 * @export
 * @interface TokenInfoResponseDto
 */
export interface TokenInfoResponseDto {
  /**
   * The status
   * @type {string}
   * @memberof TokenInfoResponseDto
   */
  status: TokenInfoResponseDto.StatusEnum;
  /**
   * The status message
   * @type {string}
   * @memberof TokenInfoResponseDto
   */
  message?: string;
  /**
   * The token
   * @type {TokenInfoDto}
   * @memberof TokenInfoResponseDto
   */
  data?: TokenInfoDto;
}

/**
 * @export
 * @namespace TokenInfoResponseDto
 */
export namespace TokenInfoResponseDto {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    OK = <any>'OK',
    ERROR = <any>'ERROR',
  }
}
/**
 *
 * @export
 * @interface TokenListCompactDto
 */
export interface TokenListCompactDto {
  /**
   * The token list id
   * @type {number}
   * @memberof TokenListCompactDto
   */
  id: number;
  /**
   * The token list name
   * @type {string}
   * @memberof TokenListCompactDto
   */
  name: string;
  /**
   * The token list note
   * @type {string}
   * @memberof TokenListCompactDto
   */
  note?: string;
}
/**
 *
 * @export
 * @interface TokenListDto
 */
export interface TokenListDto {
  /**
   * The token list id
   * @type {number}
   * @memberof TokenListDto
   */
  id: number;
  /**
   * The token list name
   * @type {string}
   * @memberof TokenListDto
   */
  name: string;
  /**
   * The token list note
   * @type {string}
   * @memberof TokenListDto
   */
  note?: string;
  /**
   * The token list tokens
   * @type {Array<TokenListItemDto>}
   * @memberof TokenListDto
   */
  items: Array<TokenListItemDto>;
  /**
   * The token list custom fields labels
   * @type {Array<CustomFieldLabelDto>}
   * @memberof TokenListDto
   */
  customFieldsLabels: Array<CustomFieldLabelDto>;
}
/**
 *
 * @export
 * @interface TokenListItemDto
 */
export interface TokenListItemDto {
  /**
   * The token list item id
   * @type {number}
   * @memberof TokenListItemDto
   */
  id: number;
  /**
   * The token list item note
   * @type {string}
   * @memberof TokenListItemDto
   */
  note?: string;
  /**
   * The token list item active status
   * @type {boolean}
   * @memberof TokenListItemDto
   */
  isActive: boolean;
  /**
   * The token list item token
   * @type {TokenInfoDto}
   * @memberof TokenListItemDto
   */
  token: TokenInfoDto;
  /**
   * The token list item custom fields values
   * @type {CustomFieldValuesDto}
   * @memberof TokenListItemDto
   */
  customFieldsValues: CustomFieldValuesDto;
  /**
   * The overall token list item usage
   * @type {number}
   * @memberof TokenListItemDto
   */
  overallUsage: number;
  /**
   * The unique users for token list item
   * @type {number}
   * @memberof TokenListItemDto
   */
  uniqueUsers: number;
}
/**
 *
 * @export
 * @interface TokenListResponseDto
 */
export interface TokenListResponseDto {
  /**
   * The status
   * @type {string}
   * @memberof TokenListResponseDto
   */
  status: TokenListResponseDto.StatusEnum;
  /**
   * The status message
   * @type {string}
   * @memberof TokenListResponseDto
   */
  message?: string;
  /**
   * The token list
   * @type {TokenListDto}
   * @memberof TokenListResponseDto
   */
  data?: TokenListDto;
}

/**
 * @export
 * @namespace TokenListResponseDto
 */
export namespace TokenListResponseDto {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    OK = <any>'OK',
    ERROR = <any>'ERROR',
  }
}
/**
 *
 * @export
 * @interface UpdateCustomFieldLabelDto
 */
export interface UpdateCustomFieldLabelDto {
  /**
   * The custom field id
   * @type {number}
   * @memberof UpdateCustomFieldLabelDto
   */
  customFieldId: number;
  /**
   * The custom field name
   * @type {string}
   * @memberof UpdateCustomFieldLabelDto
   */
  newName?: string;
  /**
   * The custom field default value
   * @type {string}
   * @memberof UpdateCustomFieldLabelDto
   */
  newDefaultValue?: string;
}
/**
 *
 * @export
 * @interface UpdateCustomFieldValueDto
 */
export interface UpdateCustomFieldValueDto {
  /**
   * The custom field  id
   * @type {number}
   * @memberof UpdateCustomFieldValueDto
   */
  customFieldId: number;
  /**
   * The custom field value
   * @type {string}
   * @memberof UpdateCustomFieldValueDto
   */
  newValue: string;
}
/**
 *
 * @export
 * @interface UpdateProjectDto
 */
export interface UpdateProjectDto {
  /**
   * The project id
   * @type {number}
   * @memberof UpdateProjectDto
   */
  projectId: number;
  /**
   * The project name
   * @type {string}
   * @memberof UpdateProjectDto
   */
  newName?: string;
  /**
   * The project note
   * @type {string}
   * @memberof UpdateProjectDto
   */
  newNote?: string;
  /**
   * The project archived status
   * @type {boolean}
   * @memberof UpdateProjectDto
   */
  isArchived?: boolean;
}
/**
 *
 * @export
 * @interface UpdateTokenListCustomFieldsDto
 */
export interface UpdateTokenListCustomFieldsDto {
  /**
   * The token list id
   * @type {number}
   * @memberof UpdateTokenListCustomFieldsDto
   */
  listId: number;
  /**
   * The token list custom field archived status
   * @type {boolean}
   * @memberof UpdateTokenListCustomFieldsDto
   */
  isArchived?: boolean;
  /**
   * The token list customFields
   * @type {Array<UpdateCustomFieldLabelDto>}
   * @memberof UpdateTokenListCustomFieldsDto
   */
  customFields: Array<UpdateCustomFieldLabelDto>;
}
/**
 *
 * @export
 * @interface UpdateTokenListDto
 */
export interface UpdateTokenListDto {
  /**
   * The token list id
   * @type {number}
   * @memberof UpdateTokenListDto
   */
  listId: number;
  /**
   * The token list name
   * @type {string}
   * @memberof UpdateTokenListDto
   */
  newName?: string;
  /**
   * The token list note
   * @type {string}
   * @memberof UpdateTokenListDto
   */
  newNote?: string;
  /**
   * The token list archived status
   * @type {boolean}
   * @memberof UpdateTokenListDto
   */
  isArchived?: boolean;
}
/**
 *
 * @export
 * @interface UpdateTokenListItemDto
 */
export interface UpdateTokenListItemDto {
  /**
   * The token list item id
   * @type {number}
   * @memberof UpdateTokenListItemDto
   */
  itemId: number;
  /**
   * The token list item note
   * @type {string}
   * @memberof UpdateTokenListItemDto
   */
  newNote?: string;
  /**
   * The token list item archived status
   * @type {boolean}
   * @memberof UpdateTokenListItemDto
   */
  isArchived?: boolean;
  /**
   * The token list item active status
   * @type {boolean}
   * @memberof UpdateTokenListItemDto
   */
  isActive?: boolean;
  /**
   * The token list item customField
   * @type {Array<UpdateCustomFieldValueDto>}
   * @memberof UpdateTokenListItemDto
   */
  customFieldsValues?: Array<UpdateCustomFieldValueDto>;
}
/**
 *
 * @export
 * @interface UserInfoDto
 */
export interface UserInfoDto {
  /**
   * The user name
   * @type {string}
   * @memberof UserInfoDto
   */
  name: string;
  /**
   * The user email
   * @type {string}
   * @memberof UserInfoDto
   */
  email: string;
  /**
   * The user projects
   * @type {Array<ProjectInfoDto>}
   * @memberof UserInfoDto
   */
  projects: Array<ProjectInfoDto>;
}
/**
 *
 * @export
 * @interface UserInfoResponseDto
 */
export interface UserInfoResponseDto {
  /**
   * The status
   * @type {string}
   * @memberof UserInfoResponseDto
   */
  status: UserInfoResponseDto.StatusEnum;
  /**
   * The status message
   * @type {string}
   * @memberof UserInfoResponseDto
   */
  message?: string;
  /**
   * The user info
   * @type {UserInfoDto}
   * @memberof UserInfoResponseDto
   */
  data?: UserInfoDto;
}

/**
 * @export
 * @namespace UserInfoResponseDto
 */
export namespace UserInfoResponseDto {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    OK = <any>'OK',
    ERROR = <any>'ERROR',
  }
}
/**
 *
 * @export
 * @interface UserSignInDto
 */
export interface UserSignInDto {
  /**
   * The user email
   * @type {string}
   * @memberof UserSignInDto
   */
  email: string;
  /**
   * The user password
   * @type {string}
   * @memberof UserSignInDto
   */
  password: string;
}
/**
 *
 * @export
 * @interface UserSignUpDto
 */
export interface UserSignUpDto {
  /**
   * The password hash of the user
   * @type {string}
   * @memberof UserSignUpDto
   */
  password: string;
  /**
   * The access code of the user
   * @type {string}
   * @memberof UserSignUpDto
   */
  accessCode: string;
}
/**
 * AdminApi - fetch parameter creator
 * @export
 */
export const AdminApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Generate access code
     * @param {GenerateAccessCodeDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateAccessCode(body: GenerateAccessCodeDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling adminControllerGenerateAccessCode.'
        );
      }
      const localVarPath = `/admin/access/generate`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'GenerateAccessCodeDto' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Generate access code
     * @param {GenerateAccessCodeDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateAccessCode(
      body: GenerateAccessCodeDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<AccessCodeResponseDto> {
      const localVarFetchArgs = AdminApiFetchParamCreator(configuration).adminControllerGenerateAccessCode(
        body,
        options
      );
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Generate access code
     * @param {GenerateAccessCodeDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adminControllerGenerateAccessCode(body: GenerateAccessCodeDto, options?: any) {
      return AdminApiFp(configuration).adminControllerGenerateAccessCode(body, options)(fetch, basePath);
    },
  };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
  /**
   *
   * @summary Generate access code
   * @param {GenerateAccessCodeDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminApi
   */
  public adminControllerGenerateAccessCode(body: GenerateAccessCodeDto, options?: any) {
    return AdminApiFp(this.configuration).adminControllerGenerateAccessCode(body, options)(this.fetch, this.basePath);
  }
}
/**
 * ListApi - fetch parameter creator
 * @export
 */
export const ListApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get user balances due to list id
     * @param {CheckAddressDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerCheckBalances(body: CheckAddressDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling listControllerCheckBalances.'
        );
      }
      const localVarPath = `/list/check/address`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'CheckAddressDto' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create token list custom fields
     * @param {CreateCustomFieldsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerCreateCustomFields(body: CreateCustomFieldsDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling listControllerCreateCustomFields.'
        );
      }
      const localVarPath = `/list/fields/create`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'CreateCustomFieldsDto' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get specific token list
     * @param {string} listId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerGetList(listId: string, options: any = {}): FetchArgs {
      // verify required parameter 'listId' is not null or undefined
      if (listId === null || listId === undefined) {
        throw new RequiredError(
          'listId',
          'Required parameter listId was null or undefined when calling listControllerGetList.'
        );
      }
      const localVarPath = `/list/{listId}`.replace(`{${'listId'}}`, encodeURIComponent(String(listId)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update token list custom fields
     * @param {UpdateTokenListCustomFieldsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerUpdateCustomFields(body: UpdateTokenListCustomFieldsDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling listControllerUpdateCustomFields.'
        );
      }
      const localVarPath = `/list/fields/update`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'UpdateTokenListCustomFieldsDto' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update token list
     * @param {UpdateTokenListDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerUpdateList(body: UpdateTokenListDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling listControllerUpdateList.'
        );
      }
      const localVarPath = `/list/update`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'UpdateTokenListDto' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ListApi - functional programming interface
 * @export
 */
export const ListApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get user balances due to list id
     * @param {CheckAddressDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerCheckBalances(
      body: CheckAddressDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<CheckAddressResponseDto> {
      const localVarFetchArgs = ListApiFetchParamCreator(configuration).listControllerCheckBalances(body, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Create token list custom fields
     * @param {CreateCustomFieldsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerCreateCustomFields(
      body: CreateCustomFieldsDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseStatusDto> {
      const localVarFetchArgs = ListApiFetchParamCreator(configuration).listControllerCreateCustomFields(body, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get specific token list
     * @param {string} listId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerGetList(
      listId: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<TokenListResponseDto> {
      const localVarFetchArgs = ListApiFetchParamCreator(configuration).listControllerGetList(listId, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update token list custom fields
     * @param {UpdateTokenListCustomFieldsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerUpdateCustomFields(
      body: UpdateTokenListCustomFieldsDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseStatusDto> {
      const localVarFetchArgs = ListApiFetchParamCreator(configuration).listControllerUpdateCustomFields(body, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update token list
     * @param {UpdateTokenListDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerUpdateList(
      body: UpdateTokenListDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseStatusDto> {
      const localVarFetchArgs = ListApiFetchParamCreator(configuration).listControllerUpdateList(body, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ListApi - factory interface
 * @export
 */
export const ListApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Get user balances due to list id
     * @param {CheckAddressDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerCheckBalances(body: CheckAddressDto, options?: any) {
      return ListApiFp(configuration).listControllerCheckBalances(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Create token list custom fields
     * @param {CreateCustomFieldsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerCreateCustomFields(body: CreateCustomFieldsDto, options?: any) {
      return ListApiFp(configuration).listControllerCreateCustomFields(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Get specific token list
     * @param {string} listId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerGetList(listId: string, options?: any) {
      return ListApiFp(configuration).listControllerGetList(listId, options)(fetch, basePath);
    },
    /**
     *
     * @summary Update token list custom fields
     * @param {UpdateTokenListCustomFieldsDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerUpdateCustomFields(body: UpdateTokenListCustomFieldsDto, options?: any) {
      return ListApiFp(configuration).listControllerUpdateCustomFields(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Update token list
     * @param {UpdateTokenListDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listControllerUpdateList(body: UpdateTokenListDto, options?: any) {
      return ListApiFp(configuration).listControllerUpdateList(body, options)(fetch, basePath);
    },
  };
};

/**
 * ListApi - object-oriented interface
 * @export
 * @class ListApi
 * @extends {BaseAPI}
 */
export class ListApi extends BaseAPI {
  /**
   *
   * @summary Get user balances due to list id
   * @param {CheckAddressDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public listControllerCheckBalances(body: CheckAddressDto, options?: any) {
    return ListApiFp(this.configuration).listControllerCheckBalances(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Create token list custom fields
   * @param {CreateCustomFieldsDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public listControllerCreateCustomFields(body: CreateCustomFieldsDto, options?: any) {
    return ListApiFp(this.configuration).listControllerCreateCustomFields(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get specific token list
   * @param {string} listId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public listControllerGetList(listId: string, options?: any) {
    return ListApiFp(this.configuration).listControllerGetList(listId, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update token list custom fields
   * @param {UpdateTokenListCustomFieldsDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public listControllerUpdateCustomFields(body: UpdateTokenListCustomFieldsDto, options?: any) {
    return ListApiFp(this.configuration).listControllerUpdateCustomFields(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update token list
   * @param {UpdateTokenListDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ListApi
   */
  public listControllerUpdateList(body: UpdateTokenListDto, options?: any) {
    return ListApiFp(this.configuration).listControllerUpdateList(body, options)(this.fetch, this.basePath);
  }
}
/**
 * ProjectApi - fetch parameter creator
 * @export
 */
export const ProjectApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Generate API key
     * @param {GenerateApiKeyDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsControllerGenerateApiKey(body: GenerateApiKeyDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling projectsControllerGenerateApiKey.'
        );
      }
      const localVarPath = `/project/apikey/generate`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'GenerateApiKeyDto' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get API key
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsControllerGetApiKey(projectId: string, options: any = {}): FetchArgs {
      // verify required parameter 'projectId' is not null or undefined
      if (projectId === null || projectId === undefined) {
        throw new RequiredError(
          'projectId',
          'Required parameter projectId was null or undefined when calling projectsControllerGetApiKey.'
        );
      }
      const localVarPath = `/project/apikey/{projectId}`.replace(
        `{${'projectId'}}`,
        encodeURIComponent(String(projectId))
      );
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update project info
     * @param {UpdateProjectDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsControllerUpdateProject(body: UpdateProjectDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling projectsControllerUpdateProject.'
        );
      }
      const localVarPath = `/project/update`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'UpdateProjectDto' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Generate API key
     * @param {GenerateApiKeyDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsControllerGenerateApiKey(
      body: GenerateApiKeyDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseStatusDto> {
      const localVarFetchArgs = ProjectApiFetchParamCreator(configuration).projectsControllerGenerateApiKey(
        body,
        options
      );
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get API key
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsControllerGetApiKey(
      projectId: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ApiKeyResponseDto> {
      const localVarFetchArgs = ProjectApiFetchParamCreator(configuration).projectsControllerGetApiKey(
        projectId,
        options
      );
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update project info
     * @param {UpdateProjectDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsControllerUpdateProject(
      body: UpdateProjectDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseStatusDto> {
      const localVarFetchArgs = ProjectApiFetchParamCreator(configuration).projectsControllerUpdateProject(
        body,
        options
      );
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Generate API key
     * @param {GenerateApiKeyDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsControllerGenerateApiKey(body: GenerateApiKeyDto, options?: any) {
      return ProjectApiFp(configuration).projectsControllerGenerateApiKey(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Get API key
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsControllerGetApiKey(projectId: string, options?: any) {
      return ProjectApiFp(configuration).projectsControllerGetApiKey(projectId, options)(fetch, basePath);
    },
    /**
     *
     * @summary Update project info
     * @param {UpdateProjectDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    projectsControllerUpdateProject(body: UpdateProjectDto, options?: any) {
      return ProjectApiFp(configuration).projectsControllerUpdateProject(body, options)(fetch, basePath);
    },
  };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
  /**
   *
   * @summary Generate API key
   * @param {GenerateApiKeyDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public projectsControllerGenerateApiKey(body: GenerateApiKeyDto, options?: any) {
    return ProjectApiFp(this.configuration).projectsControllerGenerateApiKey(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get API key
   * @param {string} projectId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public projectsControllerGetApiKey(projectId: string, options?: any) {
    return ProjectApiFp(this.configuration).projectsControllerGetApiKey(projectId, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update project info
   * @param {UpdateProjectDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProjectApi
   */
  public projectsControllerUpdateProject(body: UpdateProjectDto, options?: any) {
    return ProjectApiFp(this.configuration).projectsControllerUpdateProject(body, options)(this.fetch, this.basePath);
  }
}
/**
 * TokenApi - fetch parameter creator
 * @export
 */
export const TokenApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create token
     * @param {CreateTokenListItemDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerCreateToken(body: CreateTokenListItemDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling tokensControllerCreateToken.'
        );
      }
      const localVarPath = `/token/create`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'CreateTokenListItemDto' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get networks list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerGetNetworks(options: any = {}): FetchArgs {
      const localVarPath = `/token/networks`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get token info
     * @param {string} networkId
     * @param {string} address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerGetTokenInfo(networkId: string, address: string, options: any = {}): FetchArgs {
      // verify required parameter 'networkId' is not null or undefined
      if (networkId === null || networkId === undefined) {
        throw new RequiredError(
          'networkId',
          'Required parameter networkId was null or undefined when calling tokensControllerGetTokenInfo.'
        );
      }
      // verify required parameter 'address' is not null or undefined
      if (address === null || address === undefined) {
        throw new RequiredError(
          'address',
          'Required parameter address was null or undefined when calling tokensControllerGetTokenInfo.'
        );
      }
      const localVarPath = `/token/info/{networkId}/{address}`
        .replace(`{${'networkId'}}`, encodeURIComponent(String(networkId)))
        .replace(`{${'address'}}`, encodeURIComponent(String(address)));
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update token list item
     * @param {UpdateTokenListItemDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerUpdateToken(body: UpdateTokenListItemDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling tokensControllerUpdateToken.'
        );
      }
      const localVarPath = `/token/update`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'UpdateTokenListItemDto' !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TokenApi - functional programming interface
 * @export
 */
export const TokenApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create token
     * @param {CreateTokenListItemDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerCreateToken(
      body: CreateTokenListItemDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseStatusDto> {
      const localVarFetchArgs = TokenApiFetchParamCreator(configuration).tokensControllerCreateToken(body, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get networks list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerGetNetworks(
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<NetworksInfoResponseDto> {
      const localVarFetchArgs = TokenApiFetchParamCreator(configuration).tokensControllerGetNetworks(options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Get token info
     * @param {string} networkId
     * @param {string} address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerGetTokenInfo(
      networkId: string,
      address: string,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<TokenInfoResponseDto> {
      const localVarFetchArgs = TokenApiFetchParamCreator(configuration).tokensControllerGetTokenInfo(
        networkId,
        address,
        options
      );
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Update token list item
     * @param {UpdateTokenListItemDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerUpdateToken(
      body: UpdateTokenListItemDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseStatusDto> {
      const localVarFetchArgs = TokenApiFetchParamCreator(configuration).tokensControllerUpdateToken(body, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * TokenApi - factory interface
 * @export
 */
export const TokenApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Create token
     * @param {CreateTokenListItemDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerCreateToken(body: CreateTokenListItemDto, options?: any) {
      return TokenApiFp(configuration).tokensControllerCreateToken(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Get networks list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerGetNetworks(options?: any) {
      return TokenApiFp(configuration).tokensControllerGetNetworks(options)(fetch, basePath);
    },
    /**
     *
     * @summary Get token info
     * @param {string} networkId
     * @param {string} address
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerGetTokenInfo(networkId: string, address: string, options?: any) {
      return TokenApiFp(configuration).tokensControllerGetTokenInfo(networkId, address, options)(fetch, basePath);
    },
    /**
     *
     * @summary Update token list item
     * @param {UpdateTokenListItemDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokensControllerUpdateToken(body: UpdateTokenListItemDto, options?: any) {
      return TokenApiFp(configuration).tokensControllerUpdateToken(body, options)(fetch, basePath);
    },
  };
};

/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
export class TokenApi extends BaseAPI {
  /**
   *
   * @summary Create token
   * @param {CreateTokenListItemDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenApi
   */
  public tokensControllerCreateToken(body: CreateTokenListItemDto, options?: any) {
    return TokenApiFp(this.configuration).tokensControllerCreateToken(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get networks list
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenApi
   */
  public tokensControllerGetNetworks(options?: any) {
    return TokenApiFp(this.configuration).tokensControllerGetNetworks(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Get token info
   * @param {string} networkId
   * @param {string} address
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenApi
   */
  public tokensControllerGetTokenInfo(networkId: string, address: string, options?: any) {
    return TokenApiFp(this.configuration).tokensControllerGetTokenInfo(
      networkId,
      address,
      options
    )(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Update token list item
   * @param {UpdateTokenListItemDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TokenApi
   */
  public tokensControllerUpdateToken(body: UpdateTokenListItemDto, options?: any) {
    return TokenApiFp(this.configuration).tokensControllerUpdateToken(body, options)(this.fetch, this.basePath);
  }
}
/**
 * UserApi - fetch parameter creator
 * @export
 */
export const UserApiFetchParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerGetUserInfo(options: any = {}): FetchArgs {
      const localVarPath = `/user/info`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearer required

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Recover a user
     * @param {UserSignUpDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerRecover(body: UserSignUpDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling usersControllerRecover.'
        );
      }
      const localVarPath = `/user/recover`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'UserSignUpDto' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Sign in a user
     * @param {UserSignInDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerSignIn(body: UserSignInDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling usersControllerSignIn.'
        );
      }
      const localVarPath = `/user/signin`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'UserSignInDto' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Sign up a new user
     * @param {UserSignUpDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerSignUp(body: UserSignUpDto, options: any = {}): FetchArgs {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          'body',
          'Required parameter body was null or undefined when calling usersControllerSignUp.'
        );
      }
      const localVarPath = `/user/signup`;
      const localVarUrlObj = url.parse(localVarPath, true);
      const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      localVarUrlObj.search = null;
      localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
      const needsSerialization =
        <any>'UserSignUpDto' !== 'string' || localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.body = needsSerialization ? JSON.stringify(body || {}) : body || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerGetUserInfo(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserInfoResponseDto> {
      const localVarFetchArgs = UserApiFetchParamCreator(configuration).usersControllerGetUserInfo(options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Recover a user
     * @param {UserSignUpDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerRecover(
      body: UserSignUpDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseStatusDto> {
      const localVarFetchArgs = UserApiFetchParamCreator(configuration).usersControllerRecover(body, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Sign in a user
     * @param {UserSignInDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerSignIn(
      body: UserSignInDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<SignInResponseDto> {
      const localVarFetchArgs = UserApiFetchParamCreator(configuration).usersControllerSignIn(body, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
    /**
     *
     * @summary Sign up a new user
     * @param {UserSignUpDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerSignUp(
      body: UserSignUpDto,
      options?: any
    ): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseStatusDto> {
      const localVarFetchArgs = UserApiFetchParamCreator(configuration).usersControllerSignUp(body, options);
      return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
        return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          } else {
            throw response;
          }
        });
      };
    },
  };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
  return {
    /**
     *
     * @summary Get user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerGetUserInfo(options?: any) {
      return UserApiFp(configuration).usersControllerGetUserInfo(options)(fetch, basePath);
    },
    /**
     *
     * @summary Recover a user
     * @param {UserSignUpDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerRecover(body: UserSignUpDto, options?: any) {
      return UserApiFp(configuration).usersControllerRecover(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Sign in a user
     * @param {UserSignInDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerSignIn(body: UserSignInDto, options?: any) {
      return UserApiFp(configuration).usersControllerSignIn(body, options)(fetch, basePath);
    },
    /**
     *
     * @summary Sign up a new user
     * @param {UserSignUpDto} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    usersControllerSignUp(body: UserSignUpDto, options?: any) {
      return UserApiFp(configuration).usersControllerSignUp(body, options)(fetch, basePath);
    },
  };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @summary Get user info
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public usersControllerGetUserInfo(options?: any) {
    return UserApiFp(this.configuration).usersControllerGetUserInfo(options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Recover a user
   * @param {UserSignUpDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public usersControllerRecover(body: UserSignUpDto, options?: any) {
    return UserApiFp(this.configuration).usersControllerRecover(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Sign in a user
   * @param {UserSignInDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public usersControllerSignIn(body: UserSignInDto, options?: any) {
    return UserApiFp(this.configuration).usersControllerSignIn(body, options)(this.fetch, this.basePath);
  }

  /**
   *
   * @summary Sign up a new user
   * @param {UserSignUpDto} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public usersControllerSignUp(body: UserSignUpDto, options?: any) {
    return UserApiFp(this.configuration).usersControllerSignUp(body, options)(this.fetch, this.basePath);
  }
}
