import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserSignInDto, UserSignUpDto } from '../api/api';
import AuthModal from '../components/Auth/AuthModal';
import GradientBackground from '../components/GradientBackground';
import { useAuthActions } from '../hooks/useAuthActions';
import { useUserInfo } from '../hooks/useUserInfo';
import { AuthModalType } from '../types/modalType';

const AuthPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [modalType, setModalType] = useState<AuthModalType>(AuthModalType.LOGIN);
  const { isLoading, /*error,*/ signUp, login, confirmRecovery, getAccessCode } = useAuthActions();
  const { userInfo } = useUserInfo();
  const [errorMsg, setErrorMsg] = useState<string>('');

  const projectLink = `/${userInfo?.projects[0].id}/${userInfo?.projects[0].tokenLists[0].id}`;

  const navigate = useNavigate();

  const handleClose = () => {
    setIsModalOpen(false);
    navigate(projectLink);
  };

  const handleChangeType = (newType: AuthModalType) => {
    setModalType(newType);
    setErrorMsg('');
  };

  const handleSubmit = async (formValues: UserSignInDto | UserSignUpDto) => {
    let response;

    const actions: Record<AuthModalType, (data: any) => Promise<void>> = {
      [AuthModalType.LOGIN]: async (data: UserSignInDto) => {
        response = await login(data);
        if (response?.data?.jwt) {
          localStorage.setItem('jwt', response.data.jwt);
          handleClose();
        } else {
          setErrorMsg('Invalid email or message.');
        }
      },
      [AuthModalType.SIGNUP]: async (data: UserSignUpDto) => {
        response = await signUp(data);
        handleChangeType(AuthModalType.LOGIN);
        //setErrorMsg('Invalid access code.');
      },
      [AuthModalType.RECOVERY]: async (data: UserSignUpDto) => {
        response = await confirmRecovery(data);
        handleChangeType(AuthModalType.LOGIN);
        //setErrorMsg('Invalid access code.');
      },
    };

    await actions[modalType](formValues);
  };

  return (
    <div style={{ position: 'relative' }}>
      <GradientBackground />
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}>
        <AuthModal
          type={modalType}
          open={isModalOpen}
          onClose={handleClose}
          onSubmit={handleSubmit}
          onChangeType={handleChangeType}
          getAccessCode={getAccessCode}
          isLoading={isLoading}
          error={errorMsg}
        />
      </div>
    </div>
  );
};

export default AuthPage;
