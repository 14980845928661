import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import GradientBackground from '../components/GradientBackground';
import GradientBackgroundPartial from '../components/GradientBackgroundPartial';
import { BASE_PATH } from '../constants/routes';

const WelcomePage: React.FC = () => {
  const [isStarted, setIsStarted] = useState(false);
  const navigate = useNavigate();

  const handleStartClick = () => {
    setIsStarted(true);

    setTimeout(() => {
      navigate(BASE_PATH.AUTH);
    }, 1000);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        position: 'relative',
        overflow: 'hidden',
      }}>
      <Box
        sx={{
          width: isStarted ? 0 : '50%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingLeft: '9rem',
          paddingRight: '2.5rem',
          transition: 'width 0.5s ease, opacity 0.5s ease',
          opacity: isStarted ? 0 : 1,
          zIndex: 1,
        }}>
        <Typography variant="overline" sx={{ fontSize: '0.875rem', fontWeight: 300, mb: '1rem' }}>
          Neque porro quisquam est qui dolorem ipsum quia
        </Typography>

        <Typography
          variant="h1"
          sx={{
            fontWeight: 'bold',
            fontSize: '4rem',
            lineHeight: '1.1',
            mb: '1.5rem',
            textAlign: 'left',
          }}>
          Lorem Ipsum <br /> Neque porro qui dolorem
        </Typography>

        <Typography
          variant="body1"
          sx={{
            mb: '2rem',
            color: 'rgba(0,0,0,0.6)',
            maxWidth: '25rem',
            lineHeight: '1.6',
            textAlign: 'left',
          }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.
        </Typography>

        <Button
          variant="outlined"
          onClick={handleStartClick}
          sx={{
            borderColor: 'rgba(0, 0, 0, 0.9)',
            padding: '0.875rem 1.875rem',
            borderRadius: '3.125rem',
            fontSize: '1rem',
            fontWeight: 'bold',
            '&:hover': {
              borderColor: 'rgba(0, 0, 0, 0.7)',
            },
          }}>
          Start Now
        </Button>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: isStarted ? 0 : '60%',
          width: isStarted ? '100%' : '50%',
          height: '100vh',
          transition: 'left 0.5s ease, width 0.5s ease',
          zIndex: -1,
        }}>
        {isStarted ? <GradientBackground /> : <GradientBackgroundPartial />}
      </Box>
    </Box>
  );
};

export default WelcomePage;
