import { Box } from '@mui/material';
import React from 'react';

import { ReactComponent as CardMockupSVG } from '../assets/CardMockup.svg';

const GradientBackgroundPartial: React.FC = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <CardMockupSVG style={{ width: '100%', height: 'auto' }} />
    </Box>
  );
};

export default GradientBackgroundPartial;
