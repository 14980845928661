import { CustomFieldValuesDto } from '../api/api';

export const toCamelCase = (label: string): string => {
  return label
    .split(' ')
    .map((word, index) =>
      index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('');
};

export const formatLabelToLowerCase = (label: string): string => {
  return label.replace(/\s+/g, '').toLowerCase();
};

export const formRequestHeader = () => {
  const token = localStorage.getItem('jwt');
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
};

export const convertCustomFieldValuesToObject = (
  data: CustomFieldValuesDto | Array<Record<string, string>> | undefined
): CustomFieldValuesDto => {
  if (!data || !Array.isArray(data)) {
    return (data as CustomFieldValuesDto) || {};
  }

  return data.reduce((acc, item) => {
    const [key, value] = Object.entries(item)[0];
    (acc as any)[key.toLowerCase()] = value;
    return acc;
  }, {} as CustomFieldValuesDto);
};

export const stringToColor = (string: string): string => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};
