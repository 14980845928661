import { AuthModalType } from '../../types/modalType';

type ModalContent = {
  label: string;
};

const modalContentMapping: Record<AuthModalType, ModalContent> = {
  [AuthModalType.LOGIN]: {
    label: 'Log in to your account',
  },
  [AuthModalType.SIGNUP]: {
    label: 'Signup',
  },
  [AuthModalType.RECOVERY]: {
    label: 'Account Recovery',
  },
};

export default modalContentMapping;
