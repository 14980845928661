import React from 'react';
import { NavLink } from 'react-router-dom';

import { BASE_PATH } from '../constants/routes';

const NotFound: React.FC = () => {
  return (
    <div>
      <h1>404 - Not Found!</h1>
      <ul>
        <li>
          <NavLink to={BASE_PATH.WELCOME}> Welcome</NavLink>
        </li>
        <li>
          <NavLink to={BASE_PATH.HOME}> Dashboard </NavLink>
        </li>
        <li>
          <NavLink to={BASE_PATH.AUTH}> Auth </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default NotFound;
