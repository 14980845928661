import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUserInfo } from '../hooks/useUserInfo';
import WelcomeLayout from './WelcomeLayout';

const OpenLayoutRoutes: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { userInfo, isAuthenticated } = useUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated() && userInfo) {
      const projectLink = `/${userInfo.projects[0].id}/${userInfo.projects[0].tokenLists[0].id}`;
      navigate(projectLink, { replace: true });
    }
  }, [isAuthenticated, userInfo, navigate]);

  return <WelcomeLayout> {children}</WelcomeLayout>;
};

export default OpenLayoutRoutes;
