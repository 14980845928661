import { Button, Grid } from '@mui/material';
import React, { useRef, useState } from 'react';

import { CreateTokenListItemDto, TokenListItemDto, UpdateTokenListItemDto } from '../api/api';
import PartnerModal from '../components/Partner/PartnerModal';
import PartnerList from '../components/PartnerList';
import { usePartnersList } from '../hooks/usePartnersList';
import { PartnerModalType } from '../types/modalType';

const ListPage: React.FC = () => {
  const { partnerList, isLoading, error, addToken, updateToken } = usePartnersList();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState<PartnerModalType>(PartnerModalType.ADD_PARTNER);
  const partnerRef = useRef<TokenListItemDto>();

  const handleOpen = (type: PartnerModalType) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (formValues: CreateTokenListItemDto | UpdateTokenListItemDto) => {
    const actions: Record<PartnerModalType, (data: any) => Promise<void>> = {
      [PartnerModalType.ADD_PARTNER]: async (data: CreateTokenListItemDto) => {
        await addToken(data);
      },
      [PartnerModalType.EDIT_PARTNER]: async (data: UpdateTokenListItemDto) => {
        await updateToken(data);
      },
    };

    await actions[modalType](formValues);
    handleClose();
  };

  const handleRowClick = (partner: TokenListItemDto) => {
    partnerRef.current = partner;
    handleOpen(PartnerModalType.EDIT_PARTNER);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h1>Partner List</h1>
      {partnerList && (
        <Grid container justifyContent="flex-end" spacing={2} sx={{ mb: 2 }}>
          <Grid item>
            {
              // temporary commented
              /*
              <Button
                onClick={() => handleOpen(PartnerModalType.EDIT_PARTNER)}
                variant="outlined"
                sx={{
                  color: '#1976d2',
                  borderColor: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                    borderColor: '#1976d2',
                  },
                }}>
                EDIT
              </Button>
              */
            }
          </Grid>
          <Grid item>
            <Button onClick={() => handleOpen(PartnerModalType.ADD_PARTNER)} variant="contained">
              ADD PARTNER
            </Button>
          </Grid>
        </Grid>
      )}
      {partnerList ? <PartnerList list={partnerList} handleRowClick={handleRowClick} /> : <div>No data available</div>}
      <PartnerModal
        type={modalType}
        open={isModalOpen}
        onClose={handleClose}
        listId={partnerList?.id ?? 0}
        onSubmit={handleSubmit}
        partnerData={partnerRef.current}
        customFieldsLabels={partnerList?.customFieldsLabels}
      />
    </div>
  );
};

export default ListPage;
