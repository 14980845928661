import {
  ApiKeyResponseDto,
  CreateCustomFieldsDto,
  CreateTokenListItemDto,
  GenerateApiKeyDto,
  NetworksInfoResponseDto,
  ResponseStatusDto,
  TokenListResponseDto,
  UpdateTokenListCustomFieldsDto,
  UpdateTokenListDto,
  UpdateTokenListItemDto,
  UserInfoResponseDto,
  UserSignInDto,
  UserSignUpDto,
} from '../api/api';
import ApiClient from '../api/apiClient';
import { formRequestHeader } from './helpers';

const apiClient = ApiClient();

export const getUserInfo = async (): Promise<UserInfoResponseDto | undefined> => {
  try {
    const res = await apiClient.UserApi.usersControllerGetUserInfo(formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error fetching user information:', error);
  }
};

export const getPartnersList = async (listId: string): Promise<TokenListResponseDto | undefined> => {
  try {
    const res = await apiClient.ListApi.listControllerGetList(listId, formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error fetching partner information:', error);
  }
};

export const createToken = async (tokenData: CreateTokenListItemDto): Promise<ResponseStatusDto | undefined> => {
  try {
    const res = await apiClient.TokenApi.tokensControllerCreateToken(tokenData, formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error creating token:', error);
  }
};

export const updateToken = async (tokenData: UpdateTokenListItemDto): Promise<ResponseStatusDto | undefined> => {
  try {
    const res = await apiClient.TokenApi.tokensControllerUpdateToken(tokenData, formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error updating token:', error);
  }
};

export const generateNewApiKey = async (payload: GenerateApiKeyDto): Promise<ResponseStatusDto | undefined> => {
  try {
    const res = await apiClient.ProjectApi.projectsControllerGenerateApiKey(payload, formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error generating new API key:', error);
  }
};

export const getApiKeyByProjectId = async (projectId: string): Promise<ApiKeyResponseDto | undefined> => {
  try {
    const res = await apiClient.ProjectApi.projectsControllerGetApiKey(projectId, formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error fetching API key:', error);
  }
};

export const updateTokenList = async (updateData: UpdateTokenListDto): Promise<ResponseStatusDto | undefined> => {
  try {
    const res = await apiClient.ListApi.listControllerUpdateList(updateData, formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error updating token list:', error);
  }
};

export const updateTokenListCustomFields = async (
  updateData: UpdateTokenListCustomFieldsDto
): Promise<ResponseStatusDto | undefined> => {
  try {
    const res = await apiClient.ListApi.listControllerUpdateCustomFields(updateData, formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error updating custom fields in token list:', error);
  }
};

export const createCustomFields = async (createData: CreateCustomFieldsDto): Promise<ResponseStatusDto | undefined> => {
  try {
    const res = await apiClient.ListApi.listControllerCreateCustomFields(createData, formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error creating new custom fields:', error);
  }
};
export const getNetworksList = async (): Promise<NetworksInfoResponseDto | undefined> => {
  try {
    const res = await apiClient.TokenApi.tokensControllerGetNetworks(formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error fetching networks:', error);
  }
};
export const signUpUser = async (signUpData: UserSignUpDto): Promise<ResponseStatusDto | undefined> => {
  try {
    const res = await apiClient.UserApi.usersControllerSignUp(signUpData, formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error signing up user:', error);
  }
};

export const loginUser = async (signInData: UserSignInDto): Promise<ResponseStatusDto | undefined> => {
  try {
    const res = await apiClient.UserApi.usersControllerSignIn(signInData, formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error logging in user:', error);
  }
};

export const confirmRecovery = async (recoveryData: UserSignUpDto): Promise<ResponseStatusDto | undefined> => {
  try {
    const res = await apiClient.UserApi.usersControllerRecover(recoveryData, formRequestHeader());
    return res;
  } catch (error) {
    console.error('Error confirming recovery:', error);
  }
};
