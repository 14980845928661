import { Box } from '@mui/material';
import React, { ReactNode } from 'react';

interface WelcomeLayoutProps {
  children: ReactNode;
}

const WelcomeLayout: React.FC<WelcomeLayoutProps> = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        overflow: 'hidden',
      }}>
      {children}
    </Box>
  );
};

export default WelcomeLayout;
