import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';

import { CustomFieldLabelDto, TokenListDto } from '../api/api';
import { useTokenListManagement } from '../hooks/useTokenListManagement';
import SimpleCard from './SimpleCard';

interface CustomField {
  customFieldId?: number;
  name: string;
  customFieldType: CustomFieldLabelDto.CustomFieldTypeEnum;
  defaultValue: string;
}

interface DetailsBlockProps {
  tokenList: TokenListDto | undefined;
}

const DetailsBlock: React.FC<DetailsBlockProps> = ({ tokenList }) => {
  const { updateList, updateCustomFields, createNewCustomFields } = useTokenListManagement();

  const [formState, setFormState] = useState({
    name: '',
    note: '',
    customFields: [] as CustomField[],
  });

  const [initialState, setInitialState] = useState(formState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tokenList) {
      const initialCustomFields: CustomField[] = tokenList.customFieldsLabels.map((field) => ({
        customFieldId: field.customFieldId,
        name: field.name,
        customFieldType: field.customFieldType,
        defaultValue: field.defaultValue,
      }));

      const initialFormState = {
        name: tokenList.name,
        note: tokenList.note || '',
        customFields: initialCustomFields,
      };

      setFormState(initialFormState);
      setInitialState(initialFormState);
    }
  }, [tokenList]);

  const handleAddField = useCallback(() => {
    setFormState((prevState) => ({
      ...prevState,
      customFields: [
        ...prevState.customFields,
        {
          name: '',
          customFieldType: CustomFieldLabelDto.CustomFieldTypeEnum.STRING,
          defaultValue: '',
        },
      ],
    }));
  }, []);

  const handleCancel = useCallback(() => {
    setFormState(initialState);
  }, [initialState]);

  const handleChange = useCallback(
    (index: number, key: keyof CustomField, value: string | CustomFieldLabelDto.CustomFieldTypeEnum) => {
      setFormState((prevState) => ({
        ...prevState,
        customFields: prevState.customFields.map((field, i) => (i === index ? { ...field, [key]: value } : field)),
      }));
    },
    []
  );

  const handleSubmit = async () => {
    if (!tokenList) return;

    setLoading(true);

    try {
      const actions = [
        {
          condition: formState.name !== tokenList.name || formState.note !== tokenList.note,
          action: () =>
            updateList({
              listId: tokenList.id,
              newName: formState.name,
              newNote: formState.note,
              isArchived: false,
            }),
        },
        {
          condition: formState.customFields.some((field) => field.customFieldId),
          action: () =>
            updateCustomFields({
              listId: tokenList.id,
              customFields: formState.customFields
                .filter((field) => field.customFieldId)
                .map((field) => ({
                  customFieldId: field.customFieldId!,
                  newName: field.name,
                  newDefaultValue: field.defaultValue,
                })),
            }),
        },
        {
          condition: formState.customFields.some((field) => !field.customFieldId),
          action: () =>
            createNewCustomFields({
              listId: tokenList.id,
              customFields: formState.customFields
                .filter((field) => !field.customFieldId)
                .map((field) => ({
                  name: field.name,
                  customFieldType: field.customFieldType,
                  defaultValue: field.defaultValue,
                })),
            }),
        },
      ];

      const promises = actions.filter((item) => item.condition).map((item) => item.action());
      await Promise.all(promises);
    } catch (error) {
      console.error('Submission failed', error);
    } finally {
      setLoading(false);
    }
  };

  const fieldTypeMap: Record<
    CustomFieldLabelDto.CustomFieldTypeEnum,
    (field: CustomField, index: number) => JSX.Element
  > = {
    [CustomFieldLabelDto.CustomFieldTypeEnum.STRING]: (field, index) => (
      <TextField
        variant="outlined"
        size="small"
        value={field.defaultValue}
        onChange={(e) => handleChange(index, 'defaultValue', e.target.value)}
      />
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.BOOLEAN]: (field, index) => (
      <TextField
        variant="outlined"
        size="small"
        select
        value={field.defaultValue}
        onChange={(e) => handleChange(index, 'defaultValue', e.target.value)}>
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </TextField>
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.DATE]: (field, index) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={field.defaultValue ? dayjs(field.defaultValue) : null}
          onChange={(date: Dayjs | null) => handleChange(index, 'defaultValue', date ? date.format('YYYY-MM-DD') : '')}
          slots={{
            textField: TextField,
          }}
          slotProps={{
            textField: {
              variant: 'outlined',
              size: 'small',
            },
          }}
        />
      </LocalizationProvider>
    ),
    [CustomFieldLabelDto.CustomFieldTypeEnum.NUMBER]: (field, index) => (
      <TextField
        variant="outlined"
        size="small"
        type="number"
        value={field.defaultValue}
        onChange={(e) => handleChange(index, 'defaultValue', e.target.value)}
      />
    ),
  };

  const renderFieldByType = useCallback(
    (field: CustomField, index: number) => {
      return fieldTypeMap[field.customFieldType]
        ? fieldTypeMap[field.customFieldType](field, index)
        : fieldTypeMap[CustomFieldLabelDto.CustomFieldTypeEnum.STRING](field, index);
    },
    [fieldTypeMap, handleChange]
  );

  return (
    <SimpleCard>
      <Typography variant="h6" mb={1.5}>
        Details
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="subtitle1" color="textSecondary">
          Name
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={formState.name}
          onChange={(e) => setFormState((prevState) => ({ ...prevState, name: e.target.value }))}
        />

        <Typography variant="subtitle1" color="textSecondary">
          Description
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          value={formState.note}
          onChange={(e) => setFormState((prevState) => ({ ...prevState, note: e.target.value }))}
        />

        <Typography variant="subtitle1" color="textSecondary">
          Custom Params
        </Typography>
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap={2} alignItems="center">
          <Typography variant="subtitle2">Label</Typography>
          <Typography variant="subtitle2">Type</Typography>
          <Typography variant="subtitle2">Default Value</Typography>

          {formState.customFields.map((field, index) => (
            <React.Fragment key={field.customFieldId || index}>
              {field.customFieldId ? (
                <Typography>{field.name}</Typography>
              ) : (
                <TextField
                  variant="outlined"
                  size="small"
                  value={field.name}
                  onChange={(e) => handleChange(index, 'name', e.target.value)}
                />
              )}

              {field.customFieldId ? (
                <Typography>{field.customFieldType}</Typography>
              ) : (
                <TextField
                  variant="outlined"
                  size="small"
                  select
                  value={field.customFieldType}
                  onChange={(e) =>
                    handleChange(
                      index,
                      'customFieldType',
                      e.target.value as unknown as CustomFieldLabelDto.CustomFieldTypeEnum
                    )
                  }>
                  {Object.values(CustomFieldLabelDto.CustomFieldTypeEnum).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {renderFieldByType(field, index)}
            </React.Fragment>
          ))}
        </Box>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAddField}
            sx={{
              padding: '0.25rem 8rem',
              minWidth: '300px',
              height: '1.4rem',
            }}>
            +
          </Button>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" gap={2} mt={3}>
        <Button variant="outlined" color="inherit" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? 'Submitting...' : 'Confirm'}
        </Button>
      </Box>
    </SimpleCard>
  );
};

export default DetailsBlock;
